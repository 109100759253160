export const schedule = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_SCHEDULE_LIST_REQUEST": {
        return { ...state, isschedulelistLoading: true }
      }
      case "GET_SCHEDULE_LIST_SUCCESS": {
        return {...state , values: action.payload, scheduleList:action.payload, isschedulelistLoading : false }
      }
      case "GET_SCHEDULE_LIST_ERROR" : {
        return {...state, isschedulelistLoading : false, scheduleListError :action.payload }
      }


      // UPDATE
      case "UPDATE_SCHEDULE_REQUEST": {
        return { ...state, 
          isscheduleupdateLoading: true,
          scheduleUpdate: "",
          scheduleUpdateError: ""  
         }
      }
      case "UPDATE_SCHEDULE_SUCCESS": {
        return {...state , values: action.payload, 
          scheduleUpdate:action.payload, 
          isscheduleupdateLoading : false,
          scheduleUpdateError: "" 
        }
      }
      case "UPDATE_SCHEDULE_ERROR" : {
        return {...state, 
          isscheduleupdateLoading : false, 
          scheduleUpdateError :action.payload,
          scheduleUpdate: "" 
        }
      }


      default: {
        return state
      }
    }
  }
  