export const salary = (state = { userRole: "Staff" }, action) => {
    switch (action.type) {
  
      // LISTING  
      case "GET_SALARY_LIST_REQUEST": {
        return { ...state, issalarylistLoading: true }
      }
      case "GET_SALARY_LIST_SUCCESS": {
        return { ...state, values: action.payload, 
          salaryList: action.payload, 
          salaryListError: "",
          issalarylistLoading: false }
      }
      case "GET_SALARY_LIST_ERROR": {
        return { ...state, issalarylistLoading: false, 
          salaryListError: action.payload,
          salaryList: ""
         }
      }
  
      default: {
        return state
      }
    }
  }
  