export const request = (state = { userRole: "Staff" }, action) => {
  switch (action.type) {

    // LISTING  
    case "GET_ALL_WORK_LIST_REQUEST": {
      return { ...state, isworkalllistLoading: true }
    }
    case "GET_ALL_WORK_LIST_SUCCESS": {
      return { ...state, values: action.payload, allWorkList: action.payload, isworkalllistLoading: false }
    }
    case "GET_ALL_WORK_LIST_ERROR": {
      return { ...state, isworkalllistLoading: false, allWorkListError: action.payload }
    }

    // REQUEST LISTING  
    case "GET_REQUEST_LIST_REQUEST": {
      return { ...state, isrequestlistLoading: true }
    }
    case "GET_REQUEST_LIST_SUCCESS": {
      return { ...state, values: action.payload, allRequestList: action.payload, isrequestlistLoading: false }
    }
    case "GET_REQUEST_LIST_ERROR": {
      return { ...state, isrequestlistLoading: false, allRequestListError: action.payload }
    }

    // ADD
      case "ADD_REQUEST_REQUEST": {
        return { ...state, isrequestaddLoading: true }
      }
      case "ADD_REQUEST_SUCCESS": {
        return {...state , values: action.payload, requestAdd:action.payload, isrequestaddLoading : false }
      }
      case "ADD_REQUEST_ERROR" : {
        return {...state, isrequestaddLoading : false, requestAddError :action.payload }
      }

    // UPDATE
      case "UPDATE_REQUEST_REQUEST": {
        return { ...state, isrequestupdateLoading: true }
      }
      case "UPDATE_REQUEST_SUCCESS": {
        return {...state , values: action.payload, requestUpdate:action.payload, isrequestupdateLoading : false }
      }
      case "UPDATE_REQUEST_ERROR" : {
        return {...state, isrequestupdateLoading : false, requestUpdateError :action.payload }
      }

    // DELETE
      case "DELETE_REQUEST_REQUEST": {
        return { ...state, isrequestdeleteLoading: true }
      }
      case "DELETE_REQUEST_SUCCESS": {
        return {...state , values: action.payload, requestDelete:action.payload, isrequestdeleteLoading : false }
      }
      case "DELETE_REQUEST_ERROR" : {
        return {...state, isrequestdeleteLoading : false, requestDeleteError :action.payload }
      }

    // FIXED SALARY REQUEST
      case "ADD_FIXED_SALARY_REQUEST": {
        return { ...state, isfixedsalaryLoading: true }
      }
      case "ADD_FIXED_SALARY_SUCCESS": {
        return {...state , values: action.payload, fixedsalaryAdd:'success', isfixedsalaryLoading : false }
      }
      case "ADD_FIXED_SALARY_ERROR" : {
        return {...state, isfixedsalaryLoading : false, fixedsalaryError :action.payload }
      }

    default: {
      return state
    }
  }
}
